import React from 'react';
import logo from './assets/web-logo.svg';
import footerLogo from './assets/footer-logo.png'
import twitter from './assets/twitter.jpg'
import emi from './assets/emi.jpg'
import letter from './assets/bg_txt.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      {/* <div className="header">
        <img src={logo} className="logo" alt="logo" /> 
      </div>    */}
      <div className="banner">
        <img src={letter} className="letter" alt="letter" />
      </div>
      <div className="footer">
        <div className="footer-logo">
          <img src={footerLogo} alt="footerLogo" />
        </div>
        <div className="footer-ico">
          <a href="https://x.com/HelloGameland">
            <img src={twitter} alt="twitter" />
          </a>
          <a href="mailto: hello@gameland.network">
            <img src={emi} alt="emi" />
          </a>
        </div>
        <div className="footer-bottom">
          ©️ 2024 GameLand. All rights reserved
        </div>
      </div>
    </div>
  );
}

export default App;
